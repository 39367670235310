var render = function render(){
  var _vm$list, _vm$columns;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-component",
    class: {
      compact: _vm.compact
    }
  }, [!_vm.isMobile ? [_c('table', {
    staticClass: "w-full"
  }, [_c('thead', [_c('tr', _vm._l(_vm.columns, function (column, index) {
    return _c('td', {
      key: 'columns-' + index,
      staticClass: "whitespace-nowrap"
    }, [_vm._v(_vm._s(column.label))]);
  }), 0)]), _c('tbody', [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : _vm$list.length) > 0 ? _vm._l(_vm.list, function (entry, idx) {
    return _c('tr', {
      key: 'entry-' + idx
    }, _vm._l(_vm.columns, function (column, index) {
      return _c('td', {
        key: 'columns-' + index,
        staticClass: "align-middle leading-5"
      }, [_vm._t("table-row", null, {
        "entry": entry,
        "column": column,
        "index": idx
      })], 2);
    }), 0);
  }) : [_c('tr', [_c('td', {
    staticClass: "text-center empty",
    attrs: {
      "colspan": (_vm$columns = _vm.columns) === null || _vm$columns === void 0 ? void 0 : _vm$columns.length
    }
  }, [_vm._m(0)])])]], 2)])] : _vm._l(_vm.list, function (entry, idx) {
    return _c('div', {
      key: 'entry-' + idx,
      staticClass: "mb-6 row"
    }, _vm._l(_vm.columns, function (column, index) {
      return _c('div', {
        key: 'columns-' + index,
        staticClass: "flex items-stretch row-item"
      }, [_c('div', {
        staticClass: "w-36 flex items-center flex-shrink-0 font-medium bg-neutral-100 px-3 py-4 text-sm shadow-border-b"
      }, [_vm._v(_vm._s(column.label))]), _c('div', {
        staticClass: "w-full px-3 py-4 text-sm shadow-border-b bg-white break-all"
      }, [_vm._t("table-row", null, {
        "entry": entry,
        "column": column,
        "index": idx
      })], 2)]);
    }), 0);
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center items-center w-full flex-col gap-2"
  }, [_c('p', {}, [_vm._v("Data tidak tersedia")])]);

}]

export { render, staticRenderFns }